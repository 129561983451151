/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 89번째 로그에서는 테슬라 모델 3, GitHub 장애, 1패스워드 비지니스 플랜과 Okta 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "전기차 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/ko_kr/model3"
  }, "Model 3 | Tesla 대한민국")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/62/"
  }, "stdout_062.log: AWS re:Invent 2019 후기, 컴퓨터 히스토리 뮤지움, 맥 프로 출시 등 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/teslamotors/comments/b5k519/tesla_grafana/"
  }, "Tesla & Grafana : teslamotors")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tesla.com/autopilot"
  }, "Autopilot | Tesla")), "\n"), "\n", React.createElement(_components.h3, null, "깃헙 장애 대응, CI 이중화"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.githubstatus.com/"
  }, "GitHub Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://argoproj.github.io/argo-cd/"
  }, "Argo CD - Declarative GitOps CD for Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://argoproj.github.io/argo-cd/operator-manual/user-management/"
  }, "Overview - Argo CD - Declarative GitOps CD for Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nimbleindustries.io/2020/06/04/has-github-been-down-more-since-its-acquisition-by-microsoft/"
  }, "Has GitHub Been Down More Since Its Acquisition by Microsoft? | StatusGator")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://circleci.com/"
  }, "Continuous Integration and Delivery - CircleCI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pricing"
  }, "Pricing · Plans for every developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.github.com/en/github/site-policy/github-enterprise-cloud-addendum#enterprise-cloud-uptime-sla"
  }, "GitHub Enterprise Cloud Addendum - GitHub Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://circleci.com/docs/2.0/gh-bb-integration/"
  }, "GitHub and Bitbucket Integration - CircleCI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.gitlab.com/ee/ci/"
  }, "GitLab CI/CD | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://argoproj.github.io/argo-cd/"
  }, "Argo CD - Declarative GitOps CD for Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://azure.microsoft.com/en-gb/solutions/architecture/azure-devops-continuous-integration-and-continuous-deployment-for-azure-web-apps/"
  }, "CI/CD for Azure Web Apps | Microsoft Azure")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.circleci.com/"
  }, "CircleCI Status")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://circleci.com/blog/announcing-circleci-s-100m-series-e/"
  }, "Announcing CircleCI’s $100M Series E")), "\n"), "\n", React.createElement(_components.h3, null, "Okta"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/teams/pricing/"
  }, "Pricing for teams & businesses | 1Password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.1password.com/master-password-policy/"
  }, "Enforce Master Password requirements in 1Password Business")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.okta.com/"
  }, "Okta | The Identity Standard")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.keepersecurity.com/"
  }, "Keeper Security | Best Personal and Business Password Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.github.com/en/github/authenticating-to-github/configuring-two-factor-authentication"
  }, "Configuring two-factor authentication - GitHub Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.naver.com/ogok2010/222003061201"
  }, "옥타(Okta): Saas IAM(서비스형 계정 및 권한 관리)의 강자: 네이버블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/OKTA/"
  }, "Okta, Inc. (OKTA) Stock Price, Quote, History & News - Yahoo Finance")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/pricing.html"
  }, "Pricing · Plans for every developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://freeradius.org/"
  }, "FreeRADIUS")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
